import Cookies from 'js-cookie';
import { jwtDecode } from './jwt-decode';

export const setRefreshToken = (key: string, token: string | null) => {
  if (token) {
    const { exp } = jwtDecode(token);

    Cookies.set(key, token, {
      expires: new Date(exp * 1000),
      secure: true,
      sameSite: 'Strict',
    });
  } else {
    Cookies.remove(key);
  }
};

export const getRefreshToken = (key: string) => Cookies.get(key);
