import { alpha } from '@mui/material/styles';

interface Gradients {
  primary: string;
  secondary: string;
  tertiary: string;
}

// Extend the Palette interface to include border and backgroundGradients
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    border: string;
    backgroundGradients: Gradients;
    teamCt: {
      main: string;
    };
    teamT: {
      main: string;
    };
  }
  interface PaletteOptions {
    border?: string;
    backgroundGradients?: Gradients;
  }
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F0FAEB',
  200: '#B1B8D8',
  300: '#767DA1',
  400: '#61667D',
  500: '#3E445A',
  600: '#21273E',
  700: '#03082B',
  800: '#010417',
  900: '#00020D',
};

const PRIMARY = {
  lighter: '#71FFCC',
  light: '#0B8F64',
  main: '#02EA96',
  dark: '#01292B',
  darker: '#00A76F',
  contrastText: GREY[900],
};

const SECONDARY = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#8E33FF',
  dark: '#5119B7',
  darker: '#27097A',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#0f98df',
  dark: '#006C9C',
  darker: '#020A3D',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFC478',
  main: '#feae02',
  dark: 'rgba(252, 162, 45, 0.56)',
  darker: 'rgba(252, 162, 45, 0.16)',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FF8581',
  light: '#FF8581',
  main: '#DB1F35',
  dark: 'rgba(219, 31, 53, 0.56)',
  darker: 'rgba(219, 31, 53, 0.16)',
  contrastText: '#FFFFFF',
};

const GRADIENTS = {
  primary: 'linear-gradient(99.72deg, #02EA96 0%, #01CAA4 100%)',
  secondary: 'linear-gradient(136.38deg, #02EA96 0%, #0B8F64 100%)',
  tertiary: 'linear-gradient(136.38deg, #FF3048 0%, #AC1022 100%)',
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: '#031066',
  backgroundGradients: GRADIENTS,
  border: '#031066',
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  teamCt: {
    main: '#0074E8',
  },
  teamT: {
    main: '#FFD700',
  },
};

export const palette = (mode: 'light' | 'dark') => {
  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: GREY[100],
      secondary: GREY[300],
      disabled: GREY[500],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  return mode === 'light' ? light : dark;
};
