import {
  RankingBaseModel,
  RankingMatchDetailModel,
  ResultEnum,
  TeamRoundStatPlayer,
  UserDemoRoundResponse,
} from '@rankacy/services';
import i18next from 'i18next';
import { GAME_FACTORS_MAP, MatchFactorProps } from './types';
import { LinearProgressProps, Palette } from '@mui/material';

type RankingsWithoutNestedObjects = Omit<
  RankingMatchDetailModel,
  'ranking_current_average' | 'quantitative_score_ranking'
>;

/**
 * Prepare data for the match factors card.
 * @param ranking
 * @returns An array of match factor props.
 */
export const getGameFactors = (ranking?: RankingMatchDetailModel) =>
  Object.entries(GAME_FACTORS_MAP).map(
    ([key, { reverseScoreRating, scorePrefix, maxValue }]): MatchFactorProps => ({
      maxProgressValue: maxValue,
      title: i18next.t(key),
      newScore: ranking?.[key as keyof RankingsWithoutNestedObjects] || 0,
      averageScore: ranking?.ranking_current_average?.[key as keyof RankingBaseModel] || 0,
      reverseScoreRating,
      scorePrefix,
    })
  );

export const getMatchRounds = (
  selectedPlayerSteamId?: string,
  signedPlayerSteamId?: string,
  rounds?: UserDemoRoundResponse
) => {
  const mapToTeam = (result: ResultEnum, team: TeamRoundStatPlayer) => {
    const signedPlayer = team?.player_round_stats.find(
      (player) => player.steam_id === signedPlayerSteamId
    );

    const otherPlayers =
      team?.player_round_stats
        .filter((player) => player.steam_id !== signedPlayerSteamId)
        .sort((a, b) => a.player_name.localeCompare(b.player_name)) ?? [];

    return {
      result,
      isCt: team?.is_ct,
      isT: team?.is_t,
      isWinner: team?.is_winner,
      score: team?.end_score,
      economy: team?.economy_team_round_stat.money_after_buy,
      isSignedPlayerInTeam: !!signedPlayer,
      players: [signedPlayer, ...otherPlayers]
        .filter((player) => player !== undefined)
        .map(({ is_alive, player_name, steam_id, player_round_inventory_stats }) => ({
          isAlive: is_alive,
          name: player_name,
          weapon: player_round_inventory_stats?.find((inv) => inv.is_main)?.alias || '',
          isSelectedPlayer: steam_id === selectedPlayerSteamId,
          steamId: steam_id,
        })),
    };
  };

  const matchRounds = rounds?.round_stats.map((round) => {
    const result1 = mapToTeam(
      rounds?.team_stats[0].result as ResultEnum,
      round.team_round_stats?.[0]
    );

    const result2 = mapToTeam(
      rounds?.team_stats[1].result as ResultEnum,
      round.team_round_stats?.[1]
    );

    return {
      roundNumber: round.number,
      roundEndType: round.round_end_type,
      team1: result1.isSignedPlayerInTeam ? result1 : result2,
      team2: result1.isSignedPlayerInTeam ? result2 : result1,
    };
  });

  return matchRounds;
};

export const getMatchSwitchRound = (
  matchMode: UserDemoRoundResponse['mode'],
  playerCount?: number
) => {
  switch (matchMode) {
    case 'WINGMAN':
      return 8;
    case 'PREMIER':
    case 'COMPETITIVE':
      return 12;
    default:
      // Guess the mode if it's not provided
      return playerCount && playerCount > 2 ? 12 : 8;
  }
};

export const getRoundEndIcon = (roundEndType: string | undefined | null) => {
  if (roundEndType === 'BOMB_DETONATED') {
    return 'mdi:bomb';
  }
  if (roundEndType === 'BOMB_DEFUSED') {
    return 'mdi:content-cut';
  }
  if (roundEndType === 'TARGET_SAVED') {
    return 'mdi:timer-cancel';
  }

  return 'mdi:skull-crossbones';
};

export const getComparisonIcon = (comparison?: 'less' | 'bigger' | 'equal') => {
  switch (comparison) {
    case 'less':
      return 'mdi:chevron-down';
    case 'bigger':
      return 'mdi:chevron-up';
    default:
      return 'mdi:minus';
  }
};

export const getComparisonColor = (comparison?: 'less' | 'bigger' | 'equal') => {
  switch (comparison) {
    case 'less':
      return 'error.main';
    case 'bigger':
      return 'primary.main';
    default:
      return 'text.primary';
  }
};

export const getComparisonProgressColor = (
  comparison: 'less' | 'bigger' | 'equal'
): LinearProgressProps['color'] => {
  switch (comparison) {
    case 'less':
      return 'error';
    case 'bigger':
      return 'primary';
    default:
      return 'inherit';
  }
};

export const getScoreComparison = (
  newScore: number,
  averageScore: number,
  reverseScoreRating?: boolean
): 'less' | 'bigger' | 'equal' => {
  if (reverseScoreRating) {
    if (newScore < averageScore) return 'bigger';
    if (newScore > averageScore) return 'less';
  } else {
    if (newScore < averageScore) return 'less';
    if (newScore > averageScore) return 'bigger';
  }

  return 'equal';
};

export const normalize = (value: number, max: number, reverseScoreRating = false) =>
  ((reverseScoreRating ? 100 - value : value) / max) * 100;

export const getResultColor = (result: string, palette?: Palette) => {
  switch (result) {
    case 'VICTORY':
      return palette ? palette.primary.main : 'primary.main';
    case 'LOSS':
      return palette ? palette.error.main : 'error.main';
    default:
      return palette ? palette.text.primary : 'text.primary';
  }
};

export const getTeamResultColor = (result: ResultEnum, isMyTeam: boolean, palette?: Palette) => {
  switch (result) {
    case 'VICTORY':
      return palette ? palette.primary.main : 'primary.main';
    case 'LOSS':
      return palette ? palette.error.main : 'error.main';
    default:
      return isMyTeam
        ? palette
          ? palette.text.primary
          : 'text.primary'
        : palette
        ? palette.grey[600]
        : 'grey.600';
  }
};

export const getOppositeResult = (result: ResultEnum): ResultEnum => {
  switch (result) {
    case 'VICTORY':
      return 'LOSS';
    case 'LOSS':
      return 'VICTORY';
    default:
      return result;
  }
};

export const getResultByScore = (team1Score: number, team2Score: number): ResultEnum => {
  if (team1Score > team2Score) {
    return 'VICTORY';
  } else if (team1Score < team2Score) {
    return 'LOSS';
  } else {
    return 'DRAW';
  }
};
