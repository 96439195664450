import { config } from '../../../config';
import { getAccessToken } from '@rankacy/auth';

export const createSocketURL = () => {
  try {
    const accessToken = getAccessToken(config.ACCESS_TOKEN_KEY);

    return `${config.WS_BASE_URL}/track/?token=${accessToken}`;
  } catch (error) {
    throw new Error(`Error while creating socket URL: ${error}`);
  }
};
