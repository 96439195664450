import { apiConfig } from '@rankacy/services';
import { ApplicationSettings, CommonAppSettings, EnvParser } from '@rankacy/application';
import { featureFlagsSchema, FeatureFlagsType } from '@rankacy/feature-flags';
import { paths } from './routes/paths';
import { z } from 'zod';

const baseEnvironmentVariablesSchema = z.object({
  VITE_BACKEND_BASE_URL: z.string(),
  VITE_LAST_BUILD_TIME: z.string().optional(),
});

const environmentVariablesSchema = baseEnvironmentVariablesSchema.merge(featureFlagsSchema);

const envParser = new EnvParser(environmentVariablesSchema);

export const createConfig = () => ({
  // Backend URL from environment variables
  API_BASE_URL: envParser.get('VITE_BACKEND_BASE_URL'),

  // WebSocket base URL derived from the API base URL
  WS_BASE_URL: `${envParser.get('VITE_BACKEND_BASE_URL')}/ws`,

  // Path after login
  PATH_AFTER_LOGIN: paths.dashboard.root,

  // Default env variables
  DEV: import.meta.env.DEV,
  MODE: import.meta.env.MODE,
  PROD: import.meta.env.PROD,
  SSR: import.meta.env.SSR,

  // JWT tokens keys
  ACCESS_TOKEN_KEY: 'accessToken_CFaVhieprI2wE9nbA2hOpx_F3ZiPJaBf5usdf123dDFS',
  REFRESH_TOKEN_KEY: 'refreshToken_CFaVhieprI2wE9nbA2hOpx_F3ZiPJaBf5usdf123dDFS',

  // Feature Flags
  FEATURE_FLAGS: {
    VITE_FEATURE_REALTIME_COMMUNICATION: envParser.get('VITE_FEATURE_REALTIME_COMMUNICATION'),
    VITE_FEATURE_SOCIALS: envParser.get('VITE_FEATURE_SOCIALS'),
    VITE_FEATURE_STRATEGY_BOARD: envParser.get('VITE_FEATURE_STRATEGY_BOARD'),
    VITE_FEATURE_NOTIFICATIONS: envParser.get('VITE_FEATURE_NOTIFICATIONS'),
    VITE_FEATURE_STREETVIEW: envParser.get('VITE_FEATURE_STREETVIEW'),
    VITE_FEATURE_GLOBAL_ANNOUNCEMENT: envParser.get('VITE_FEATURE_GLOBAL_ANNOUNCEMENT'),
    VITE_FEATURE_GIVEAWAY: envParser.get('VITE_FEATURE_GIVEAWAY'),
    VITE_FEATURE_MAINTENANCE: envParser.get('VITE_FEATURE_MAINTENANCE'),
    VITE_FEATURE_HIGHLIGHTS_ISSUES_DIALOG: envParser.get('VITE_FEATURE_HIGHLIGHTS_ISSUES_DIALOG'),
  } as FeatureFlagsType,

  // Analytics tools
  GTM_ID: 'GTM-5JCTZ9P7',
  HOTJAR_ID: 4933149,
  HOTJAR_SNIPPET_VERSION: 6,

  // App-specific settings
  APP_NAME: 'rankacy',
  SENTRY_DSN:
    'https://adc6d4a4b9434d6d9645b3fcd9ec62e8@o4507808804241408.ingest.de.sentry.io/4507811384983632',

  // Build information
  LAST_BUILD_TIME: envParser.get('VITE_LAST_BUILD_TIME'),

  // Global tick rate
  TICKS_PER_SECOND: 64,
});

export type RankacyAppSettings = CommonAppSettings & {
  WS_BASE_URL: string;
  FEATURE_FLAGS: FeatureFlagsType;
  GTM_ID: string;
  HOTJAR_ID: number;
  HOTJAR_SNIPPET_VERSION: number;
  SENTRY_DSN: string;
  TICKS_PER_SECOND: number;
  DEV: boolean;
  MODE: string;
  PROD: boolean;
  SSR: boolean;
};

export const settings = new ApplicationSettings<RankacyAppSettings>('rankacyApp');

settings.initialize(createConfig());

apiConfig.initialize({
  apiBaseUrl: settings.values.API_BASE_URL,
  accessTokenKey: settings.values.ACCESS_TOKEN_KEY,
  refreshTokenKey: settings.values.REFRESH_TOKEN_KEY,
});

export const config = settings.values;
