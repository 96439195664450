import { Button, ButtonProps, IconButton, Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useLocales } from '@rankacy/intl';
import { useBoolean } from '@rankacy/hooks';
import { Iconify } from '@rankacy/iconify';

type Props = ButtonProps & {
  content: string;
  minimal?: boolean;
  tooltip?: string;
};

const COPY_TIMEOUT = 5000;

export const CopyToClipboardButton = ({
  children,
  content,
  tooltip,
  minimal = false,
  ...other
}: PropsWithChildren<Props>) => {
  const { t } = useLocales();

  const copied = useBoolean(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    copied.onTrue();
    setTimeout(() => copied.onFalse(), COPY_TIMEOUT);
  };

  return (
    <Tooltip placement="top" title={tooltip}>
      {minimal ? (
        <IconButton
          {...other}
          sx={{ color: copied.value ? 'primary.main' : 'inherit', ...other.sx }}
          onClick={(e) => {
            e.stopPropagation();
            handleCopy();
          }}
        >
          <Iconify icon={copied.value ? 'mdi:file-check' : 'mdi:content-copy'} />
        </IconButton>
      ) : (
        <Button
          startIcon={
            <Iconify height={24} icon={copied.value ? 'mdi:check' : 'mdi:share'} width={24} />
          }
          sx={{
            '&:hover': {
              color: 'primary.main',
            },
            ...other.sx,
          }}
          variant="text"
          onClick={handleCopy}
          {...other}
        >
          {copied.value ? t('link_copied') : children}
        </Button>
      )}
    </Tooltip>
  );
};
