import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetHighlightProcessingApiHighlightsProcessingGetQuery } from '@rankacy/services';

const _10_SECONDS = 10_000;

/**
 * useGetGeneratingHighlights hook manages the tracking of game highlights being generated.
 *
 * This hook uses polling to periodically check the status of game highlights generation.
 * It updates the state to reflect whether there are any highlights currently being generated
 * and invalidates relevant API cache if the count of generating highlights decreases.
 *
 * @returns {
 *   generatingCount: number - The count of highlights that are currently being generated.
 *   isDoneGenerating: boolean - Indicates if the highlights generation process is complete.
 *   remainingProcessingSeconds: number - The estimated time remaining for processing in seconds.
 * }
 */
export const useGetGeneratingHighlights = () => {
  const [checkingForChanges, setCheckingForChanges] = useState(false);

  const [lastCount, setLastCount] = useState(0);

  const dispatch = useDispatch();

  const { data } = useGetHighlightProcessingApiHighlightsProcessingGetQuery(undefined, {
    pollingInterval: checkingForChanges ? _10_SECONDS : undefined,
  });

  const highlightsGeneratedByUser = useMemo(
    () => data?.results?.filter(({ is_premade }) => !is_premade) || [],
    [data]
  );

  const generatingHighlights = useMemo(
    () =>
      highlightsGeneratedByUser?.filter(
        ({ status }) => status === 'PROCESSING' || status === 'NEW' || status === 'PENDING'
      ) || [],
    [highlightsGeneratedByUser]
  );
  const failedHighlights = useMemo(
    () => highlightsGeneratedByUser?.filter(({ status }) => status === 'FAILED') || [],
    [highlightsGeneratedByUser]
  );

  const generatingHighlightsCount = generatingHighlights.length;

  useEffect(() => {
    if (generatingHighlightsCount > 0) {
      setCheckingForChanges(true);
    } else {
      setCheckingForChanges(false);
    }

    if (generatingHighlightsCount < lastCount) {
      dispatch({
        type: `api/invalidateTags`,
        payload: ['highlights'],
      });
    }

    setLastCount(generatingHighlightsCount);
  }, [generatingHighlightsCount, lastCount, setCheckingForChanges, dispatch]);

  return {
    generatingHighlights,
    failedHighlights,
    //
    generatingHighlightsCount,
    failedHighlightsCount: failedHighlights.length,
    //
    isDoneGenerating: generatingHighlights.length === 0,
  };
};
