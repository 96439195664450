import { IconButton, Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { useLocales } from '@rankacy/intl';
import { useBoolean } from '@rankacy/hooks';
import { Indicator } from './styles';
import { BackgroundTaskItem } from './background-task-item';
import { Iconify } from '@rankacy/iconify';
import { RouterLink } from '@rankacy/routing';
import { paths } from '../../routes/paths';
import { Scrollbar } from '@rankacy/components';
import { pxToRem } from '@rankacy/theme';

type Props = {
  activeBackgroundTasks?: number;
  title?: string;
  failedDemosCount?: number;
  failedHighlightsCount?: number;
  queuedDemosCount?: number;
  tasksWithProgressComponent?: ReactElement | null;
  onClearFailedDemos?: VoidFunction;
  onClearFailedHighlights?: VoidFunction;
  alert?: ReactElement | null;
};

export const BackgroundTasksIndicator = ({
  activeBackgroundTasks,
  failedDemosCount,
  failedHighlightsCount,
  queuedDemosCount,
  title,
  tasksWithProgressComponent,
  alert,
  onClearFailedHighlights,
  onClearFailedDemos,
}: Props) => {
  const { t } = useLocales();

  const { value: opened, onToggle } = useBoolean(true);

  const someTaskActive =
    !!failedHighlightsCount ||
    !!failedDemosCount ||
    !!queuedDemosCount ||
    tasksWithProgressComponent;

  if (!someTaskActive) return null;

  const renderHead = (
    <Stack
      sx={{ gap: 1, flexDirection: 'row', justifyContent: 'space-between', cursor: 'pointer' }}
      onClick={onToggle}
    >
      <Typography
        sx={{
          fontFamily: ({ typography }) => typography.button.fontFamily,
          flexGrow: 1,
          fontWeight: 400,
        }}
        variant="subtitle2"
      >
        {title || t('background_tasks', { count: activeBackgroundTasks })}
      </Typography>

      <IconButton
        disableRipple
        sx={{ p: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          onToggle();
        }}
      >
        <Iconify
          color="text.primary"
          icon={opened ? 'mdi:chevron-down' : 'mdi:chevron-up'}
          width={24}
        />
      </IconButton>
    </Stack>
  );

  return (
    <Indicator>
      {renderHead}

      <Scrollbar forceVisible sx={{ maxHeight: pxToRem(400) }}>
        <Stack sx={{ gap: 1, py: opened ? 1 : 0 }}>
          {opened && (
            <>
              {alert}

              {tasksWithProgressComponent}

              {!!failedDemosCount && (
                <>
                  <Typography
                    color="text.secondary"
                    component={RouterLink}
                    href={paths.dashboard.match.list}
                    variant="caption"
                  >
                    {t('failed_demos')}
                  </Typography>

                  <BackgroundTaskItem
                    abortTask={onClearFailedDemos}
                    label={t('demos_failed_count', { count: failedDemosCount })}
                    type="failed"
                  />
                </>
              )}

              {!!failedHighlightsCount && (
                <>
                  <Typography
                    color="text.secondary"
                    component={RouterLink}
                    href={paths.dashboard.highlights.root}
                    variant="caption"
                  >
                    {t('failed_highlights')}
                  </Typography>

                  <BackgroundTaskItem
                    abortTask={onClearFailedHighlights}
                    label={t('highlights_failed_count', { count: failedHighlightsCount })}
                    type="failed"
                  />
                </>
              )}

              {!!queuedDemosCount && (
                <>
                  <Typography
                    color="text.secondary"
                    component={RouterLink}
                    href={paths.dashboard.match.list}
                    variant="caption"
                  >
                    {t('queued_demos')}
                  </Typography>

                  <BackgroundTaskItem
                    label={t('demos_queued_count', { count: queuedDemosCount })}
                    type="queued"
                  />
                </>
              )}
            </>
          )}
        </Stack>
      </Scrollbar>
    </Indicator>
  );
};
