/* eslint-disable consistent-return */
import { useEffect, useReducer, useCallback, useMemo, PropsWithChildren } from 'react';
import {
  api,
  useConfirmEmailApiUserConfirmRegistrationPostMutation,
  useUserSteamConnectApiUserSteamConnectPostMutation,
  useUserSteamLoginApiUserSteamLoginPostMutation,
  useUserSteamDisconnectApiUserSteamDisconnectPutMutation,
  useLoginForAccessTokenApiUserTokenPostMutation,
  useReadCurrentUserApiUserDetailGetQuery,
  SteamLoginDataRequest,
  BodyLoginForAccessTokenApiUserTokenPost,
  useLazyReadCurrentUserApiUserDetailGetQuery,
  useRefreshTokenApiUserTokenRefreshPostMutation,
} from '@rankacy/services';
import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import { AuthContext } from './auth-context';
import { ActionMapType, AuthStateType, AuthUserType } from '../types';
import { config } from '../../config';
import { getAccessToken, isValidToken, setAccessToken, setRefreshToken } from '@rankacy/auth';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }

  return state;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchReduxApi = useDispatch();

  const [disconnectSteam] = useUserSteamDisconnectApiUserSteamDisconnectPutMutation();
  const [confirmRegistrationApi] = useConfirmEmailApiUserConfirmRegistrationPostMutation();
  const [connectToSteam] = useUserSteamConnectApiUserSteamConnectPostMutation();
  const [loginUserWithSteam] = useUserSteamLoginApiUserSteamLoginPostMutation();
  const [getUserDetail] = useLazyReadCurrentUserApiUserDetailGetQuery();

  const [getTokensOnLogin] = useLoginForAccessTokenApiUserTokenPostMutation();
  const [refreshAccessToken] = useRefreshTokenApiUserTokenRefreshPostMutation();

  const { data: getUser } = useReadCurrentUserApiUserDetailGetQuery(undefined, {
    skip: isNil(getAccessToken(config.ACCESS_TOKEN_KEY)),
  });

  const initialize = useCallback(async () => {
    try {
      const accessToken = getAccessToken(config.ACCESS_TOKEN_KEY);
      const refreshToken = getAccessToken(config.REFRESH_TOKEN_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setAccessToken(config.ACCESS_TOKEN_KEY, accessToken);

        const user = await getUserDetail().unwrap();

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });

        return;
      }

      if (refreshToken && isValidToken(refreshToken)) {
        const { access_token, refresh_token } = await refreshAccessToken({
          tokenRefreshRequest: { refresh_token: refreshToken },
        }).unwrap();

        setAccessToken(config.ACCESS_TOKEN_KEY, access_token);
        setRefreshToken(config.REFRESH_TOKEN_KEY, refresh_token);

        const user = await getUserDetail().unwrap();

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });

        return;
      }

      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, [getUserDetail, refreshAccessToken]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (getUser) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: getUser,
        },
      });
    }
  }, [getUser]);

  // LOGIN
  const login = useCallback(
    async (emailOrUsername: string, password: string) => {
      const formData = new URLSearchParams({ username: emailOrUsername, password });

      const { access_token, refresh_token, user } = await getTokensOnLogin({
        bodyLoginForAccessTokenApiUserTokenPost:
          formData as unknown as BodyLoginForAccessTokenApiUserTokenPost,
      }).unwrap();

      setAccessToken(config.ACCESS_TOKEN_KEY, access_token);
      setRefreshToken(config.REFRESH_TOKEN_KEY, refresh_token);

      dispatchReduxApi(api.util.resetApiState());

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    },
    [getTokensOnLogin, dispatchReduxApi]
  );

  const confirmRegistration = useCallback(
    async (email: string, token: string) => {
      const { access_token, refresh_token, user } = await confirmRegistrationApi({
        confirmEmailRequest: {
          email,
          token,
        },
      }).unwrap();

      setAccessToken(config.ACCESS_TOKEN_KEY, access_token);
      setRefreshToken(config.REFRESH_TOKEN_KEY, refresh_token);

      dispatchReduxApi(api.util.resetApiState());

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    },
    [confirmRegistrationApi, dispatchReduxApi]
  );

  // LOGIN WITH STEAM
  const loginWithSteam = useCallback(
    async (steamCredentials: SteamLoginDataRequest) => {
      const response = (await loginUserWithSteam({
        steamLoginDataRequest: steamCredentials,
      })) as any;

      if (response?.data) {
        const {
          data: { access, user },
        } = response;

        setAccessToken(config.ACCESS_TOKEN_KEY, access);

        dispatchReduxApi(api.util.resetApiState());

        dispatch({
          type: Types.LOGIN,
          payload: {
            user,
          },
        });

        return true;
      }
      if (response?.error) {
        return false;
      }
    },
    [loginUserWithSteam, dispatchReduxApi]
  );

  const disconnectFromSteam = useCallback(async () => {
    await disconnectSteam().unwrap();

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: { ...state.user, steam_id: null } as AuthUserType,
      },
    });
  }, [disconnectSteam, state.user]);

  const connectWithSteam = useCallback(
    async (steamCredentials: SteamLoginDataRequest) => {
      await connectToSteam({ steamLoginDataRequest: steamCredentials }).unwrap();

      const user = await getUserDetail().unwrap();

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    },
    [connectToSteam, getUserDetail]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setAccessToken(config.ACCESS_TOKEN_KEY, null);
    setRefreshToken(config.REFRESH_TOKEN_KEY, null);

    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      confirmRegistration,
      loginWithSteam,
      connectWithSteam,
      disconnectFromSteam,
      logout,
    }),
    [
      login,
      logout,
      confirmRegistration,
      loginWithSteam,
      connectWithSteam,
      disconnectFromSteam,
      state.user,
      status,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};
