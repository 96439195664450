import { useDropzone } from 'react-dropzone';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useLocales } from '@rankacy/intl';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { UploadProps } from './types';
import RejectionFiles from './error/errors-rejection-files';
import MultiFilePreview from './preview-multi-file';
import SingleFilePreview from './preview-single-file';
import { Iconify } from '@rankacy/iconify';
import { Image } from '../image';

const Upload = ({
  withoutIllustration = false,
  disabled,
  simpleVariant = false,
  multiple = false,
  error,
  helperText,
  invalidFileTypeMessage,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  onRename,
  sx,
  ...other
}: UploadProps) => {
  const { t } = useLocales();

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const hasFile = !!file && !multiple;

  const hasFiles = !!files && multiple && !!files.length;

  const hasError = !!error;

  const renderPlaceholder = simpleVariant ? (
    <Iconify icon="eva:cloud-upload-fill" width={28} />
  ) : (
    <Stack alignItems="center" flexWrap="wrap" justifyContent="center" spacing={3}>
      {!withoutIllustration && (
        <Image src="/assets/images/highlights/upload_demo.webp" sx={{ width: 1, maxWidth: 200 }} />
      )}
      <Stack spacing={1} sx={{ textAlign: 'center' }}>
        <Typography variant="h6">{t('drop_or_select')}</Typography>
        <Typography sx={{ color: 'text.secondary' }} variant="body2">
          {t('drop_or_select_long')}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderSinglePreview = (
    <SingleFilePreview imgUrl={typeof file === 'string' ? file : file?.preview} />
  );

  const removeSinglePreview = hasFile && onDelete && (
    <IconButton
      size="small"
      sx={{
        top: 16,
        right: 16,
        zIndex: 9,
        position: 'absolute',
        color: (theme) => alpha(theme.palette.common.white, 0.8),
        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
        '&:hover': {
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
        },
      }}
      onClick={onDelete}
    >
      <Iconify icon="mingcute:close-line" width={18} />
    </IconButton>
  );

  const renderMultiPreview = hasFiles && (
    <>
      <Box sx={{ my: 3 }}>
        <MultiFilePreview
          files={files}
          thumbnail={thumbnail}
          onRemove={onRemove}
          onRename={onRename}
        />
      </Box>

      <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
        {onRemoveAll && (
          <Button color="inherit" size="medium" variant="outlined" onClick={onRemoveAll}>
            {t('remove_all')}
          </Button>
        )}

        {onUpload && (
          <Button
            size="medium"
            startIcon={<Iconify icon="eva:cloud-upload-fill" />}
            variant="contained"
            onClick={onUpload}
          >
            {t('upload')}
          </Button>
        )}
      </Stack>
    </>
  );

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <Box
        {...getRootProps()}
        sx={{
          p: simpleVariant ? 2 : 5,
          outline: 'none',
          textAlign: 'center',
          borderRadius: 1,
          cursor: 'pointer',
          overflow: 'hidden',
          position: 'relative',
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
          border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
          transition: (theme) => theme.transitions.create(['opacity', 'padding']),
          '&:hover': {
            opacity: 0.72,
          },
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
          ...(hasFile && {
            padding: '24% 0',
          }),
        }}
      >
        <input {...getInputProps()} />

        {hasFile ? renderSinglePreview : renderPlaceholder}
      </Box>

      {removeSinglePreview}

      {helperText}

      <RejectionFiles
        fileRejections={fileRejections}
        invalidFileTypeMessage={invalidFileTypeMessage}
      />

      {renderMultiPreview}
    </Box>
  );
};

export default Upload;
