import { hotjar } from 'react-hotjar';

type HotjarArgs = {
  id: number;
  sv: number;
  enabled: boolean;
};

export const initializeHotjar = ({ id, sv, enabled }: HotjarArgs) => {
  if (!enabled || hotjar.initialized()) {
    return;
  }

  hotjar.initialize({ id, sv });
};

export const identifyUserHotjar = (userId: string, properties: Record<string, unknown>) => {
  if (!hotjar.initialized()) {
    return;
  }

  hotjar.identify(userId, properties);
};
