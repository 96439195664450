import axios from 'axios';
import {
  api,
  ChangeUserMatchFavoriteApiDemosUserMatchIdChangeFavoritePutApiArg,
  ChangeUserMatchFavoriteApiDemosUserMatchIdChangeFavoritePutApiResponse,
  GetHighlightShortsFreshApiHighlightsShortsFreshGetApiArg,
  GetHighlightShortsFreshApiHighlightsShortsFreshGetApiResponse,
  GetHighlightShortsTopApiHighlightsShortsTopGetApiArg,
  GetHighlightShortsTopApiHighlightsShortsTopGetApiResponse,
  LikeHighlightApiHighlightsLikeHighlightIdPutApiArg,
  LikeHighlightApiHighlightsLikeHighlightIdPutApiResponse,
  RenameHighlightApiHighlightsRenameHighlightIdPutApiArg,
  RenameHighlightApiHighlightsRenameHighlightIdPutApiResponse,
  UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiArg,
  UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiResponse,
} from './api';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { config } from '../config';
import { removeUploadingDemoByHash, setUploadingDemosWithProgress } from '../redux';
import { AxiosAbortControllerRegistry } from '../utils';
import { getAccessToken } from '@rankacy/auth';

const addTagTypes = ['user'];

// These are non-typed endpoints, these are implemented because of non-supported django serializer types (Backend API).
// Use primary api endpoints.
const userResource = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      renameHighlight: builder.mutation<
        RenameHighlightApiHighlightsRenameHighlightIdPutApiResponse,
        RenameHighlightApiHighlightsRenameHighlightIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlights/rename/${queryArg.highlightId}`,
          method: 'PUT',
          body: queryArg.highlightRenameRequest,
        }),
        async onQueryStarted(
          { highlightId, highlightRenameRequest },
          { dispatch, queryFulfilled, getState }
        ) {
          const highlightsCacheEntires = api.util.selectInvalidatedBy(getState(), [
            { type: 'highlights' },
          ]);

          const patchResults: PatchCollection[] = [];

          highlightsCacheEntires
            .filter(
              ({ endpointName }) => endpointName === 'getHighlightsLibraryApiHighlightsListGet'
            )
            .forEach(({ originalArgs, endpointName }) => {
              patchResults.push(
                dispatch(
                  api.util.updateQueryData(
                    endpointName as 'getHighlightsLibraryApiHighlightsListGet',
                    originalArgs,
                    (draft) => {
                      const source = draft.results.map(({ id, name, ...other }) => {
                        if (id === highlightId) {
                          return {
                            ...other,
                            id,
                            name: highlightRenameRequest.name,
                          };
                        }

                        return { ...other, id, name };
                      });
                      draft.results = source;
                    }
                  )
                )
              );
            });

          try {
            await queryFulfilled;
          } catch {
            patchResults.forEach((patch) => patch.undo());
          }
        },
      }),
      getFreshShorts: builder.query<
        GetHighlightShortsFreshApiHighlightsShortsFreshGetApiResponse,
        GetHighlightShortsFreshApiHighlightsShortsFreshGetApiArg
      >({
        query: ({ lastDatetime, firstDatetime, pageSize }) => ({
          url: '/api/highlights/shorts/fresh',
          params: {
            last_datetime: lastDatetime,
            first_datetime: firstDatetime,
            page_size: pageSize,
          },
        }),
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newData, { arg: { firstDatetime, lastDatetime } }) => {
          if (!firstDatetime && !lastDatetime) {
            return newData;
          }

          const newCache = {
            newer_count: newData.newer_count,
            highlights: [...currentCache.highlights, ...newData.highlights],
            result_count: newData.highlights.length,
          };

          return newCache;
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        providesTags: ['highlights'],
      }),
      getTopShorts: builder.query<
        GetHighlightShortsTopApiHighlightsShortsTopGetApiResponse,
        GetHighlightShortsTopApiHighlightsShortsTopGetApiArg
      >({
        query: ({ lastDatetime, firstDatetime, pageSize, highlightIds }) => ({
          url: '/api/highlights/shorts/top',
          params: {
            last_datetime: lastDatetime,
            first_datetime: firstDatetime,
            page_size: pageSize,
            highlight_ids: highlightIds,
          },
        }),
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newData, { arg: { firstDatetime, lastDatetime } }) => {
          if (!firstDatetime && !lastDatetime) {
            return newData;
          }

          const newCache = {
            newer_count: newData.newer_count,
            highlights: [...currentCache.highlights, ...newData.highlights],
            result_count: newData.highlights.length,
          };

          return newCache;
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        providesTags: ['highlights'],
      }),
      markFavoriteMatch: builder.mutation<
        ChangeUserMatchFavoriteApiDemosUserMatchIdChangeFavoritePutApiResponse,
        ChangeUserMatchFavoriteApiDemosUserMatchIdChangeFavoritePutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/demos/${queryArg.userMatchId}/change-favorite`,
          method: 'PUT',
        }),
        async onQueryStarted({ userMatchId }, { dispatch, queryFulfilled, getState }) {
          const highlightsCacheEntires = api.util.selectInvalidatedBy(getState(), [
            { type: 'demos' },
          ]);

          const patchResults: PatchCollection[] = [];

          highlightsCacheEntires
            .filter(({ endpointName }) => endpointName === 'getUserMatchListApiDemosGet')
            .forEach(({ originalArgs, endpointName }) => {
              patchResults.push(
                dispatch(
                  api.util.updateQueryData(
                    endpointName as 'getUserMatchListApiDemosGet',
                    originalArgs,
                    (draft) => {
                      const source = draft.results.map(({ id, is_favorite, ...other }) => {
                        if (id === userMatchId) {
                          return {
                            ...other,
                            id,
                            is_favorite: !is_favorite,
                          };
                        }

                        return { ...other, id, is_favorite };
                      });
                      draft.results = source;
                    }
                  )
                )
              );
            });

          try {
            await queryFulfilled;
          } catch {
            patchResults.forEach((patch) => patch.undo());
          }
        },
      }),

      updateLikeToPost: builder.mutation<
        LikeHighlightApiHighlightsLikeHighlightIdPutApiResponse,
        LikeHighlightApiHighlightsLikeHighlightIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlights/like/${queryArg.highlightId}`,
          method: 'PUT',
        }),
        async onQueryStarted({ highlightId }, { dispatch, queryFulfilled, getState }) {
          const highlightsCacheEntires = api.util.selectInvalidatedBy(getState(), [
            { type: 'highlights' },
          ]);

          const patchResults: PatchCollection[] = [];

          highlightsCacheEntires
            .filter(({ endpointName }) => endpointName === 'getTopShorts')
            .forEach(({ originalArgs, endpointName }) => {
              patchResults.push(
                dispatch(
                  api.util.updateQueryData(
                    'getTopShorts' as 'getHighlightShortsTopApiHighlightsShortsTopGet',
                    originalArgs,
                    (draft) => {
                      const source = draft.highlights.map(
                        ({ is_liked, likes_count, id, ...other }) => {
                          if (id === highlightId) {
                            return {
                              ...other,
                              id,
                              likes_count: !is_liked
                                ? (likes_count ?? 0) + 1
                                : (likes_count ?? 0) - 1,
                              is_liked: !is_liked,
                            };
                          }

                          return { ...other, id, likes_count, is_liked };
                        }
                      );
                      draft.highlights = source;
                    }
                  )
                )
              );
            });

          highlightsCacheEntires
            .filter(({ endpointName }) => endpointName === 'getFreshShorts')
            .forEach(({ originalArgs, endpointName }) => {
              patchResults.push(
                dispatch(
                  api.util.updateQueryData(
                    'getFreshShorts' as 'getHighlightShortsFreshApiHighlightsShortsFreshGet',
                    originalArgs,
                    (draft) => {
                      const source = draft.highlights.map(
                        ({ is_liked, likes_count, id, ...other }) => {
                          if (id === highlightId) {
                            return {
                              ...other,
                              id,
                              likes_count: !is_liked
                                ? (likes_count ?? 0) + 1
                                : (likes_count ?? 0) - 1,
                              is_liked: !is_liked,
                            };
                          }

                          return { ...other, id, likes_count, is_liked };
                        }
                      );
                      draft.highlights = source;
                    }
                  )
                )
              );
            });

          highlightsCacheEntires
            .filter(
              ({ endpointName }) => endpointName === 'getHighlightsLibraryApiHighlightsListGet'
            )
            .forEach(({ originalArgs, endpointName }) => {
              patchResults.push(
                dispatch(
                  api.util.updateQueryData(
                    endpointName as 'getHighlightsLibraryApiHighlightsListGet',
                    originalArgs,
                    (draft) => {
                      const source = draft.results.map(
                        ({ is_liked, likes_count, id, ...other }) => {
                          if (id === highlightId) {
                            return {
                              ...other,
                              id,
                              likes_count: !is_liked
                                ? (likes_count ?? 0) + 1
                                : (likes_count ?? 0) - 1,
                              is_liked: !is_liked,
                            };
                          }

                          return { ...other, id, likes_count, is_liked };
                        }
                      );
                      draft.results = source;
                    }
                  )
                )
              );
            });

          highlightsCacheEntires
            .filter(
              ({ endpointName }) =>
                endpointName === 'getHighlightsBySteamIdApiHighlightsListSteamIdGet'
            )
            .forEach(({ originalArgs, endpointName }) => {
              patchResults.push(
                dispatch(
                  api.util.updateQueryData(
                    endpointName as 'getHighlightsBySteamIdApiHighlightsListSteamIdGet',
                    originalArgs,
                    (draft) => {
                      const source = draft.results.map(
                        ({ is_liked, likes_count, id, ...other }) => {
                          if (id === highlightId) {
                            return {
                              ...other,
                              id,
                              likes_count: !is_liked
                                ? (likes_count ?? 0) + 1
                                : (likes_count ?? 0) - 1,
                              is_liked: !is_liked,
                            };
                          }

                          return { ...other, id, likes_count, is_liked };
                        }
                      );
                      draft.results = source;
                    }
                  )
                )
              );
            });

          try {
            await queryFulfilled;
          } catch {
            patchResults.forEach((patch) => patch.undo());
          }
        },
      }),

      updateHighlightsSettings: builder.mutation<
        UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiResponse,
        UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiArg
      >({
        query: ({ highlightSettingModelResponse }) => ({
          url: `/api/highlight-settings/highlight-settings`,
          method: 'POST',
          body: highlightSettingModelResponse,
        }),
        invalidatesTags: ['games-settings'],
        async onQueryStarted({ highlightSettingModelResponse }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData(
              'getHighlightSettingsApiHighlightSettingsHighlightSettingsGet',
              undefined,
              (draft) => {
                Object.assign(draft, highlightSettingModelResponse);
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      updateAvatar: builder.mutation({
        query: (body) => ({
          url: '/api/user/update/avatar',
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['user'],
      }),
      uploadDemoWithProgress: builder.mutation({
        queryFn: async ({ hash, data }: { hash: string; data: FormData }, reduxApi) => {
          try {
            const accessToken = getAccessToken(config.values.accessTokenKey);
            const abortController = new AbortController();

            AxiosAbortControllerRegistry.add(hash, abortController);

            const alias = (data.get('alias') as string) ?? hash;

            const result = await axios.post(`${config.values.apiBaseUrl}/api/demos/upload`, data, {
              signal: abortController.signal,
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              onUploadProgress: (upload: any) => {
                const uploadProgress = Math.round((100 * upload.loaded) / upload.total);

                reduxApi.dispatch(
                  setUploadingDemosWithProgress({
                    hash,
                    progress: uploadProgress,
                    name: alias,
                  })
                );
              },
            });

            reduxApi.dispatch({
              type: `api/invalidateTags`,
              payload: ['demos'],
            });

            return { data: result.data };
          } catch (axiosError) {
            const err = axiosError as any;

            return {
              error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
              },
            };
          } finally {
            AxiosAbortControllerRegistry.remove(hash);
            reduxApi.dispatch(removeUploadingDemoByHash(hash));
          }
        },
      }),
      getDemoFile: builder.query({
        queryFn: async ({ demoHash }) => {
          try {
            const accessToken = getAccessToken(config.values.accessTokenKey);
            const response = await axios.get(
              `${config.values.apiBaseUrl}/api/system-admin/demo/${demoHash}/download`,
              {
                headers: { Authorization: `Bearer ${accessToken}` },
                responseType: 'blob',
              }
            );

            return response;
          } catch (axiosError) {
            const err = axiosError as any;

            return {
              error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
              },
            };
          }
        },
      }),
    }),
    overrideExisting: false,
  });

export const {
  useLazyGetDemoByHashApiDemosDemoHashGetQuery,
  useUpdateAvatarMutation,
  useUploadDemoWithProgressMutation,
  useUpdateHighlightsSettingsMutation,
  useLazyReadCurrentUserApiUserDetailGetQuery,
  useGetTopShortsQuery,
  useUpdateLikeToPostMutation,
  useMarkFavoriteMatchMutation,
  useGetFreshShortsQuery,
  useRenameHighlightMutation,
  useLazyGetDemoFileQuery,
} = userResource;

// --------------------------------------------------------------------------------------------
// Hooks used just for change the response format
// --------------------------------------------------------------------------------------------
// const useGetMyMatchesForTimeline = () =>
//   useGetUserMatchListApiDemosGetQuery(
//     {},
//     {
//       selectFromResult: ({ data, ...rest }) => ({
//         data:
//           (
//             data?.results?.filter(
//               ({ quantitative_rank, sixth_sense_rank }: UserMatchResult) =>
//                 quantitative_rank !== null && sixth_sense_rank !== null
//             ) ?? []
//           )
//             .map(({ demo_id, my_result, score, map_name, created_at }: UserMatchResult) => ({
//               demo_id,
//               my_team_score:
//                 (my_result === 'VICTORY' && max(score?.split(':'))) ||
//                 (my_result === 'LOSS' && min(score?.split(':'))) ||
//                 (my_result === 'DRAW' && score?.split(':')[0]) ||
//                 0,
//               enemy_team_score:
//                 (my_result === 'VICTORY' && min(score?.split(':'))) ||
//                 (my_result === 'LOSS' && max(score?.split(':'))) ||
//                 (my_result === 'DRAW' && score?.split(':')[0]) ||
//                 0,
//               my_result,
//               map_name,
//               date_played: created_at,
//             }))
//             .sort((a: any, b: any) => b.date_played - a.date_played) ?? [],
//         ...rest,
//       }),
//     }
//   );

// export { useGetMyMatchesForTimeline };
