import { PlayerAttackStat, PlayerMatchStatScoreboard, ResultEnum } from '@rankacy/services';

type GameFactorsMap = {
  [key in
    | 'aim_rank'
    | 'movement_rank'
    | 'utility_rank'
    | 'dick_factor_rank'
    | 'sixth_sense_rank']: {
    icon: string;
    reverseScoreRating?: boolean;
    scorePrefix?: string;
    maxValue?: number;
  };
};
export const GAME_FACTORS_MAP: GameFactorsMap = {
  aim_rank: { icon: 'mdi:target' },
  movement_rank: { icon: 'mdi:run' },
  utility_rank: { icon: 'game-icons:flash-grenade' },
  dick_factor_rank: {
    icon: 'mdi:emoticon-sad-outline',
    reverseScoreRating: true,
    scorePrefix: '-',
  },
  sixth_sense_rank: { icon: 'mdi:brain', maxValue: 500 },
};

export type Scoreboard = Omit<PlayerMatchStatScoreboard, 'total_rank'> & {
  teamId: number;
  id: number;
  isMyTeam: boolean;
  totalRank: number;
  result: ResultEnum;
  isSelectedPlayerTeam: boolean;
};

export type MatchRoundTeam = {
  result: ResultEnum;
  isCt: boolean;
  isT: boolean;
  isWinner: boolean | null | undefined;
  score: number;
  economy: number;
  players: {
    isAlive: boolean;
    name: string;
    weapon: string;
    isSelectedPlayer?: boolean;
    steamId: string;
  }[];
};

export const MATCH_HALF_TIME_ROUND = 12;

export type MatchFactorProps = {
  title: string;
  newScore: number;
  averageScore: number;
  reverseScoreRating?: boolean;
  scorePrefix?: string;
  maxProgressValue?: number;
};

export const CHART_MIN_DATA_POINTS = 2;

export type SelectedPlayer = {
  steamId: string;
  name: string;
};

export type Attacker = PlayerAttackStat & { result: ResultEnum };
