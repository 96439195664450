import { useEffect } from 'react';
import { useAuthContext } from '../../auth';
import { identifyUserHotjar } from '@rankacy/analytics';
import { useGetUserCreatedAtApiUserCreatedAtGetQuery } from '@rankacy/services';
import { differenceInDays } from 'date-fns';
import { useSteamConnectionAndGamesStatus } from '../../sections/steam/hooks';

type Props = {
  enabled: boolean;
};

export const HotjarUserIdentifier = ({ enabled }: Props) =>
  enabled ? <HotjarUserIdentifierExecutor /> : null;

const HotjarUserIdentifierExecutor = () => {
  const { user } = useAuthContext();

  const {
    isConnectedToSteam,
    autoDownloadCodesProvided,
    isLoading: isLoadingSteamCredentials,
  } = useSteamConnectionAndGamesStatus();

  const { data: createdAt, isLoading: isLoadingUserRegistration } =
    useGetUserCreatedAtApiUserCreatedAtGetQuery();

  useEffect(() => {
    if (isLoadingSteamCredentials || isLoadingUserRegistration) {
      return;
    }

    if (user && createdAt) {
      const daysFromRegistration = differenceInDays(new Date(), new Date(createdAt));

      identifyUserHotjar(user?.id, {
        email: user?.email,
        username: user?.username,
        daysFromRegistration,
        isConnectedToSteam,
        autoDownloadCodesProvided,
      });
    }
  }, [
    user,
    createdAt,
    isConnectedToSteam,
    isLoadingSteamCredentials,
    isLoadingUserRegistration,
    autoDownloadCodesProvided,
  ]);

  return null;
};
