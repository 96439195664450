import { useEffect, useState, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useLocales } from '@rankacy/intl';
import { Iconify } from '@rankacy/iconify';
import { Upload } from '@rankacy/components';

type Props = DialogProps & {
  title?: string;
  onCreate?: VoidFunction;
  onUpdate?: VoidFunction;
  onUpload?: (files: File[]) => void;
  folderName?: string;
  onChangeFolderName?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  open: boolean;
  onClose: VoidFunction;
};

export const DemoUploadDialog = ({
  title = 'Upload Files',
  open,
  onClose,
  onCreate,
  onUpdate,
  onUpload,
  folderName,
  onChangeFolderName,
  ...other
}: Props) => {
  const { t } = useLocales();

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles([...files, ...newFiles]);
    },
    [files]
  );

  const handleUpload = () => {
    onUpload?.(files);

    onClose();
  };

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  const handleFileRename = (inputFile: File | string, newName: string) => {
    const renamedFiles = files.map((file) => {
      if (file === inputFile) {
        Object.assign(file, {
          aliasName: newName,
        });
      }

      return file;
    });

    setFiles(renamedFiles as File[]);
  };

  const isUploadDisabled = files.some(({ name }) => !name?.length) || !files.length;

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>{title}</DialogTitle>

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
        {(onCreate || onUpdate) && (
          <TextField
            fullWidth
            label={t('folder_name')}
            sx={{ mb: 3 }}
            value={folderName}
            onChange={onChangeFolderName}
          />
        )}

        <Upload
          multiple
          accept={{ 'application/octet-stream': ['.dem', '.gz', '.bzip'] }}
          files={files}
          invalidFileTypeMessage={t('invalid_file_type_dem')}
          onDrop={handleDrop}
          onRemove={handleRemoveFile}
          onRename={handleFileRename}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isUploadDisabled}
          startIcon={<Iconify icon="eva:cloud-upload-fill" />}
          variant="contained"
          onClick={handleUpload}
        >
          {t('upload')}
        </Button>

        {!!files.length && (
          <Button color="inherit" variant="outlined" onClick={handleRemoveAllFiles}>
            {t('remove_all')}
          </Button>
        )}

        {(onCreate || onUpdate) && (
          <Stack direction="row" flexGrow={1} justifyContent="flex-end">
            <Button variant="soft" onClick={onCreate || onUpdate}>
              {onUpdate ? t('save') : t('create')}
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
};
