import { m } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useAuthContext } from '../..//auth';
import { paths } from '../../routes/paths';
import { Namespaces, useLocales } from '@rankacy/intl';
import { useMemo } from 'react';
import { CustomPopover, usePopover, varHover } from '@rankacy/components';
import { useRouter } from '@rankacy/routing';

type Props = {
  type: 'header' | 'nav-vertical';
};

const AccountPopover = ({ type }: Props) => {
  const { t } = useLocales(Namespaces.Common);

  const router = useRouter();

  const { user } = useAuthContext();

  const { logout } = useAuthContext();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();

      popover.onClose();
      router.replace('/');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  const menuOptions = useMemo(
    () => [
      {
        labelTranslationKey: 'settings',
        linkTo: paths.dashboard.socials.account,
      },
    ],
    []
  );

  return (
    <>
      <IconButton
        component={m.button}
        sx={{
          width: 46,
          height: 46,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
        variants={varHover(1.05)}
        whileHover="hover"
        whileTap="tap"
        onClick={popover.onOpen}
      >
        <Avatar
          alt={user?.username}
          src={user?.avatar_path || ''}
          sx={{
            width: 42,
            height: 42,
          }}
        />
      </IconButton>

      <CustomPopover
        arrow={type === 'header' ? 'top-right' : 'left-top'}
        open={popover.open}
        sx={{ width: 240, p: 0 }}
        onClose={popover.onClose}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography noWrap variant="subtitle2">
            {user?.username}
          </Typography>

          <Typography noWrap sx={{ color: 'text.secondary' }} variant="body2">
            {user?.country?.name}
          </Typography>
        </Box>

        <Divider sx={{ marginX: 2 }} />

        <Stack sx={{ p: 1 }}>
          {menuOptions.map(({ labelTranslationKey, linkTo }) => (
            <MenuItem key={labelTranslationKey} onClick={() => handleClickItem(linkTo)}>
              {t(labelTranslationKey)}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ marginX: 2 }} />

        <MenuItem
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
          onClick={handleLogout}
        >
          {t('logout')}
        </MenuItem>
      </CustomPopover>
    </>
  );
};

export default AccountPopover;
